//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import minxin from '../../components/minxin'
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    index: {
      type: Number,
      default: 1,
    },
  },
  mixins: [minxin],
  components: {
    OpenData,
  },

  methods: {
    img_src(num) {
      if (num) {
        let img = require(`../../images/urgency${num}.png`)
        return {
          backgroundImage: `url(${img})`,
        }
      }
      return {}
    },
    // 簽收
    acceptBill({ id, documentType }) {
      let url = 'showMobileApproval'
      // if (documentType === 2) {
      //   url = 'mobileBillDetail'
      // }
      this.$router.push({ name: url, query: { id, type: documentType, todo: 'accept' } })
    },
    // 審批
    senpi({ approvalId, nodeId, id, documentType }) {
      this.$router.push({ name: 'mobileApproval', query: { id, approvalId, nodeId, type: documentType } })
    },
    Filter(arr, s) {
      if (!s) return ''
      const values = arr.filter((item) => Number(item.code) === s)
      if (values.length > 0) {
        return values[0].value
      }
    },
    toDetail({ id, documentType }) {
      this.$router.push({ name: 'showMobileApproval', query: { id, type: documentType } })
    },
  },
}
