import { axios } from '@/utils/request'

/**
 * 查詢公文流轉單據主表
 *
 * @author yx
 * @date 2022-06-10 18:16:00
 */
export function documentBillPage (parameter) {
  return axios({
    url: '/flow/documentBill/page',
    method: 'get',
    params: parameter
  })
}

/**
 * 獲取已登記的公文列表
 * @param {*} parameter 分頁信息
 * @returns
 */
export function documentRegisterBillPage (parameter) {
  return axios({
    url: '/flow/documentBill/register',
    method: 'get',
    params: parameter
  })
}

/**
 * 查詢公文流轉單據主表
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function documentBillDispatchPage (parameter) {
  return axios({
    url: '/flow/documentBill/dispatchPage',
    method: 'get',
    params: parameter
  })
}

/**
 * 查詢公文流轉單據主表
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function documentBillIndexPage (parameter) {
  return axios({
    url: '/flow/documentBill/indexPage',
    method: 'get',
    params: parameter
  })
}

/**
 * 查詢是否顯示引導彈框
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function getPopup () {
  return axios({
    url: '/flow/documentHomePopup/getPopup',
    method: 'get'
  })
}

/**
 * 設置是否顯示引導彈框
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function closePopup (data) {
  return axios({
    url: '/flow/documentHomePopup/closePopup',
    method: 'post',
    data
  })
}

/**
 * 初始化首頁數值
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function pendingNumber () {
  return axios({
    url: '/flow/documentBill/pending',
    method: 'get'
  })
}

/**
 * 初始化首頁數值
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function statisticsNumber () {
  return axios({
    url: '/flow/documentBill/statistics',
    method: 'get'
  })
}

/**
 * 接收公文
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function acceptDocumentBill (id) {
  return axios({
    url: `/flow/documentBill/accept/${id}`,
    method: 'post'
  })
}

/**
 * 獲取公司id
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function getCorpByUser (params) {
  return axios({
    url: `/wx/getCorpByUser`,
    method: 'get',
    params
  })
}

/**
 * 發起人處理節點（下一步）
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function acceptNextNode (parameter) {
  return axios({
    url: `/flow/documentBill/nextNode`,
    method: 'post',
    data: parameter
  })
}

/**
 * 發起人處理節點（下一步） 王念添加催辦接口
 *
 * @author xyl
 * @date 2023-11-9 12:16:00
 */
export function urgingNodeUser(parameter) {
  return axios({
    url: `/flow/documentBill/urging`,
    method: 'post',
    data: parameter
  })
}

/**
 * 發起人處理節點（加簽）
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function acceptAddNextNode (parameter) {
  return axios({
    url: `/flow/documentBill/addNode`,
    method: 'post',
    data: parameter
  })
}

/**
 * 發起人處理抄送
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
export const carbonCopyBill = parameter => axios({
  url: `/flow/documentBill/recipient`,
  method: 'post',
  data: parameter
})

/**
 * 查詢公文流轉單據主表
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function documentBillAuditPage (parameter) {
  return axios({
    url: '/flow/documentBill/auditPage',
    method: 'get',
    params: parameter
  })
}

/**
 * xxxxxxxxxxx
 * @param {*} params
 * @returns
 */
export const documentBillHomePage = params => axios({
  url: '/flow/documentBill/homePage',
  method: 'get',
  params
})

/**
 * 查詢公文流轉單據主表---我已處理
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function documentBillAuditedPage (parameter) {
  return axios({
    url: '/flow/documentBill/audited',
    method: 'get',
    params: parameter
  })
}

/**
 * 公文流轉單據主表列表
 *
 * @author yx
 * @date 2022-06-10 18:16:00
 */
export function documentBillList (parameter) {
  return axios({
    url: '/flow/documentBill/list',
    method: 'get',
    params: parameter
  })
}

/**
 * 添加公文流轉單據主表
 *
 * @author yx
 * @date 2022-06-10 18:16:00
 */
export function documentBillAdd (parameter) {
  return axios({
    url: '/flow/documentBill/addRegistration',
    method: 'post',
    data: parameter
  })
}

/**
 * 編輯公文流轉單據主表
 *
 * @author yx
 * @date 2022-06-10 18:16:00
 */
export function documentBillEdit (parameter) {
  return axios({
    url: '/flow/documentBill/edit',
    method: 'post',
    data: parameter
  })
}

/**
 * 刪除公文流轉單據主表
 *
 * @author yx
 * @date 2022-06-10 18:16:00
 */
export function documentBillDelete (parameter) {
  return axios({
    url: '/flow/documentBill/delete',
    method: 'post',
    data: parameter
  })
}

/**
 * 導出公文流轉單據主表
 *
 * @author yx
 * @date 2022-06-10 18:16:00
 */
export function documentBillExport (parameter) {
  return axios({
    url: '/flow/documentBill/export',
    method: 'get',
    params: parameter,
    responseType: 'blob'
  })
}

/**
 * 發起審批
 * @author xyl
 * @param {*} parameter
 * @returns
 */
export const createApproval = parameter => axios({
  url: '/flow/documentBill/processes',
  method: 'post',
  data: parameter
})

/**
 * 擬稿發文-發送審批
 * @param {*} parameter
 * @returns
 */
export const addOfficial = parameter => axios({
  url: '/flow/documentBill/addOfficial',
  method: 'post',
  data: parameter
})
/**
 * 擬稿發文-編輯發送審批
 * @param {*} parameter
 * @returns
 */
 export const editOfficial = parameter => axios({
  url: '/flow/documentBill/editOfficial',
  method: 'post',
  data: parameter
})

/**
 * 擬稿發文-保存草稿
 * @param {*} parameter
 * @returns
 */
 export const addOfficialDraft = data => axios({
  url: '/flow/documentBill/addOfficialDraft',
  method: 'post',
  data
})
/**
 * 擬稿發文-編輯保存草稿
 * @param {*} parameter
 * @returns
 */
 export const editOfficialDraft = data => axios({
  url: '/flow/documentBill/editOfficialDraft',
  method: 'post',
  data
})

/**
 * 刪除公文
 * @author xyl
 * @param {*} parameter
 * @returns
 */
export const delDocumentBill = parameter => axios({
  url: '/flow/documentBill/delete',
  method: 'post',
  data: parameter
})

export function documentBillDetail (parameter) {
  return axios({
    url: '/flow/documentBill/detail',
    method: 'get',
    params: parameter
  })
}

export function documentBillAllPage (parameter) {
  return axios({
    url: `/flow/documentBill/allPage`,
    method: 'get',
    params: parameter
  })
}

/**
 * 審批
 * @author xyl
 * @param {*} parameter
 * @returns
 */
export const documentBillAudit = parameter => axios({
  url: '/flow/documentBill/audit',
  method: 'post',
  data: parameter
})


/**
 * 文書歸檔列表
 *
 * @author yx
 * @date 2022-06-10 18:16:00
 */
 export function documentArchivedList (parameter) {
  return axios({
    url: '/archived/page',
    method: 'post',
    data: parameter
  })
}

/**
 * 快速發文新增
 *
 * @author yx
 * @date 2022-06-10 18:16:00
 */
 export function documentBillAddDoc (parameter) {
  return axios({
    url: '/flow/documentBill/addDoc',
    method: 'post',
    data: parameter
  })
}

/**
 * 快速發文編輯
 *
 * @author yx
 * @date 2022-06-10 18:16:00
 */
 export function documentBillEditDoc (parameter) {
  return axios({
    url: '/flow/documentBill/editDoc',
    method: 'post',
    data: parameter
  })
}

/**
 * 收文登記編輯
 *
 * @author yx
 * @date 2022-06-10 18:16:00
 */
 export function documentBillEditRegistration (parameter) {
  return axios({
    url: '/flow/documentBill/editRegistration',
    method: 'post',
    data: parameter
  })
}

// 
/**
 * 收文登記編輯
 *
 * @author yx
 * @date 2022-06-10 18:16:00
 */
 export function uploadFileTransform (parameter) {
  return axios({
    url: '/wx/transform',
    method: 'get',
    params: parameter
  })
}
/**
 * 永久刪除
 * @param {*} data
 * @returns
 */
export const documentBillExpurgate = data => axios({
  url: '/flow/documentBill/expurgate',
  method: 'post',
  data
})
/**
 * 恢復
 * @param {*} data
 * @returns
 */
export const documentBillCountermand = data => axios({
  url: '/flow/documentBill/countermand',
  method: 'post',
  data
})
export const wxTransform = params => axios({
  url: '/wx/transform',
  method: 'get',
  params
})
export const wxRealFile = params => axios({
  url: '/wx/getRealFile',
  method: 'get',
  params
})
/**
 * 獲取企業列表
 * @param {*} params
 * @returns
 */
export const wxCorpPage = params => axios({
  url: '/wx/corp/page',
  method: 'get',
  params
})
/**
 * 編輯
 * @param {*} data
 * @returns
 */
export const wxCorpEdit = data => axios({
  url: 'wx/corp/edit',
  method: 'post',
  data
})
/**
 * 編輯授權日期
 * @param {*} data
 * @returns
 */
 export const wxOvertime = data => axios({
  url: 'wx/corp/overtime',
  method: 'post',
  data
})